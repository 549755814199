var swiperproduct = new Swiper(".product__swiper", {
    breakpoints: {
        480: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 24
        }
    },
    navigation: {
        nextEl: ".product__swiper-button-next",
        prevEl: ".product__swiper-button-prev",
    }
});
var swiperBanner = new Swiper(".banner__swiper", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".banner__swiper-button-next",
        prevEl: ".banner__swiper-button-prev",
    },
    autoplay: {
        delay: 3000
    },
    pagination: {
        el: '.banner__swiper-pagination',
        clickable: true,
    },
});
var swiperpartner = new Swiper(".partner__swiper", {
    breakpoints: {
        480: {
            slidesPerView: 2,
            spaceBetween: 10
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 6,
            spaceBetween: 24
        }
    },
    navigation: {
        nextEl: ".partner__swiper-button-next",
        prevEl: ".partner__swiper-button-prev",
    }
});
const customerBrandSwiper = new Swiper('.customer-brand-swiper', {
    autoplay: {
        delay: 1500,
        disableOnInteraction: false,
    },

    navigation: {
        nextEl: '.swiper-button-next__customer-brand',
        prevEl: '.swiper-button-prev__customer-brand',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 6,
        }
    },
});
const certificateSwiper = new Swiper('.certificate-swiper', {
    // navigation: {
    //   nextEl: '.swiper-button-next__product-detail',
    //   prevEl: '.swiper-button-prev__product-detail',
    // },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        480: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
        },
        1000: {
            slidesPerView: 2,
        }
    },
});

const teamIntroSwiper = new Swiper('.team-intro-swiper', {
    // navigation: {
    //   nextEl: '.swiper-button-next__product-detail',
    //   prevEl: '.swiper-button-prev__product-detail',
    // },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        480: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 3,
        },
        1000: {
            slidesPerView: 4,
        }
    },
});

const contactForm = document.getElementById("contactForm");
const contactFooter = document.getElementById("registerFormNews");
const modalContact = document.getElementById("modalContact");
const modalContactFooter = document.getElementById("modalContactFooter");
if (contactForm){
    contactForm.addEventListener("submit", function (e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var formData = new FormData(form);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        const modal = new bootstrap.Modal(modalContact);
        modal.show();
        form.reset();

        xhr.send(formData);
    });
}
if (contactFooter){
    contactFooter.addEventListener("submit", function (e) {
        e.preventDefault();

        var form = e.target;
        var actionUrl = form.getAttribute("action");

        var formData = new FormData(form);

        var xhr = new XMLHttpRequest();
        xhr.open("POST", actionUrl, true);
        const modal = new bootstrap.Modal(modalContactFooter);
        modal.show();
        form.reset();

        xhr.send(formData);
    });
}
